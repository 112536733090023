<template>
  <div id="virtual-tour"></div>
</template>

<script>
export default {
  props: {
    tourPath: {
      type: String,
      required: true,
    },
    playerType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentScripts: [],
      noScript: [],
    };
  },
  watch: {
    tourPath(newVal) {
      if (newVal) {
        this.loadTour();
      }
    },
  },
  mounted() {
    this.loadTour();
  },
  beforeDestroy() {
    this.destroyPano();
  },
  methods: {
    createPano() {
      this.destroyPano();

      const vt = document.getElementById("virtual-tour");
      const pano = document.createElement("div");
      pano.setAttribute("id", "pano");
      vt.appendChild(pano);

      if (this.playerType === "pano-2015") {
        /* eslint-disable-next-line no-undef */
        embedpano({
          swf: `${this.tourPath}/tour.swf`,
          xml: `${this.tourPath}/tour.xml`,
          target: "pano",
          html5: "only",
          mobilescale: 1.0,
          passQueryParameters: true,
        });
      } else if (this.playerType === "pano-2025") {
        /* eslint-disable-next-line no-undef */
        const panoPlayer = new pano2vrPlayer("pano");
        /* eslint-disable-next-line no-undef */
        new pano2vrSkin(panoPlayer, `${this.tourPath}/`);
        panoPlayer.readConfigUrlAsync(`${this.tourPath}/pano.xml`);
      }
    },

    destroyPano() {
      const panoTest = document.getElementById("pano");
      if (panoTest && this.playerType === "pano-2015") {
        /* eslint-disable-next-line no-undef */
        removepano("pano");
      }
    },

    async loadTour() {
      await this.clearTour();

      if (this.playerType === "pano-2015") {
        this.loadScripts(["tour.js"]);
      } else if (this.playerType === "pano-2025") {
        this.loadScripts([
          "pano2vr_player.js",
          "skin.js",
          "webxr/three.min.js",
          "webxr/webxr-polyfill.min.js",
        ]);
      }
    },

    async clearTour() {
      this.currentScripts.forEach(async (element) => {
        await this.$unloadScript(element);
      });

      if (this.noScript.includes(this.tourPath)) {
        this.destroyPano();
        this.currentScripts = [];
        return;
      }
    },

    async loadScripts(scripts) {
      const promises = scripts.map((newScript) => {
        const script = `${this.tourPath}/${newScript}`;
        return Promise.resolve(this.$loadScript(script));
      });

      Promise.all(promises)
        .then(() => {
          this.currentScripts = scripts;
          setTimeout(() => {
            this.createPano();
          }, 1000);
        })
        .catch(() => {
          this.destroyPano();
          this.currentScripts = [];
          this.noScript.push(this.tourPath);
        });
    },
  },
};
</script>

<style lang="scss">
#virtual-tour {
  width: 100%;
}
#pano {
  width: 100%;
  height: 250px;
  min-height: 250px;
  z-index: 1;
  @media (min-width: 640px) {
    height: 350px;
    min-height: 350px;
  }
  @media (min-width: 1024px) {
    height: 450px;
    min-height: 450px;
  }
  @media (min-width: 1280px) {
    height: 520px;
    min-height: 520px;
  }
  @media (min-width: 1440px) {
    height: 600px;
    min-height: 600px;
  }
  @media (min-width: 1920px) {
    height: 700px;
    min-height: 700px;
  }
}
</style>
